import Home from 'Pages/Home';
import Credits from 'Pages/Credits';
import About from 'Pages/About';
import { paths, redirect_paths } from './paths';

const REWARDS_URI = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
const DISCORD_INVITE_URI = 'https://discord.gg/2APYtQwVCf';

const redirect_routes = [{ from: redirect_paths.cast, to: paths.credits }];

const routes = [
    { path: paths.about, component: About },
    { path: paths.credits, component: Credits },
    {
        path: paths.rewards,
        component: () => {
            window.location.href = REWARDS_URI;
            return null;
        },
    },
    {
        path: paths.discord,
        component: () => {
            window.location.href = DISCORD_INVITE_URI;
            return null;
        },
    },
    { path: paths.index, component: Home },
];

export { redirect_routes, routes };
